import { mapGetters, mapActions } from "vuex";
// import { mixin as clickaway } from "vue-clickaway";
import RequestCard from "../components/RequestCard";

export const requestMixin = {
    components: {
        RequestCard,
    },
    // mixins: [clickaway],
    data: () => ({
        filter: false,
    }),
    computed: {
        ...mapGetters(["getYears", "getCompanyRequests"]),
    },
    methods: {
        ...mapActions(["getRequestYears"]),
        away() {
            this.filter = false;
        },
    },
}