<template>
  <div class="requests" style="width: 100% !important">
    <div v-if="canViewRequests">
      <h1
        class="p-4 text-2xl text-gray-500 sm:text-left bg-gray-primary w-full"
      >
        Submitted Requests : {{ getRequestCount }}
      </h1>
      <div v-if="requestMode == 'single'" class="sticky flex items-center">
        <button
          @click="goBack"
          class="ml-4 mt-4 text-gray-500 focus:outline-none"
        >
          <i class="fas fa-angle-left"></i>
          Go Back
        </button>
        <!-- <h3>{{ title }}</h3> -->
      </div>
      <!-- <transition name="fade"> -->
      <component :is="requestMode"></component>
      <!-- </transition> -->
    </div>
    <div v-else>
      <content-holder :title="'Tcc Requests'" class="p-6">
        <unauthorized-access></unauthorized-access>
      </content-holder>
    </div>
  </div>
</template>

<script>
import group from "../components/requests/group";
import single from "../components/requests/single";
import emitter from "tiny-emitter/instance";
import { mapGetters } from "vuex";
import UnauthorizedAccess from "../components/shared/UnauthorizedAccess.vue";
import ContentHolder from "../components/ContentHolder.vue";
import { views } from "@/helpers/login_details.js";
export default {
  name: "Requests",
  components: {
    group,
    single,
    UnauthorizedAccess,
    ContentHolder,
  },
  data: () => ({
    requestMode: "group",
    canViewRequests: false,
  }),
  methods: {
    goBack() {
      this.requestMode = "group";
      this.$store.dispatch("resetRequestSingles");
    },
  },
  computed: {
    ...mapGetters(["getRequestCount"]),
  },
  mounted() {
    // this.$store.state.request_count = 0;
  },
  created() {
    if (views.includes("Tcc")) {
      this.canViewRequests = true;
      //   eslint-disable-next-line no-unused-vars
      emitter.on("showAll", (payload) => {
        this.requestMode = "single";
        this.$store.dispatch("getRequestSingles", payload).then(() => {});
      });
    } else {
      this.canViewRequests = false;
    }

    // this.$store.state.request_count = 0;
  },
};
</script>

<style lang="scss">
.requests {
  height: calc(100vh - 3.5rem) !important;
}

.request-items {
  height: 67vh !important;
  overflow-y: scroll !important;
}
</style>
