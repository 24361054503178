<template>
  <div class="group">
    <div class="w-36 relative inline-block text-left mt-4 ml-4">
      <div v-click-away="away">
        <button
          type="button"
          @click="filter = !filter"
          class="inline-flex items-center justify-between w-full rounded-md border-2 border-gray-300 shadow-md px-4 py-2 bg-white text-sm font-semibold text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-primary"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
        >
          {{ selectedYear || "Select Year" }}
          <i
            :class="`fas ${
              filter ? 'fa-angle-down' : 'fa-angle-up'
            } ml-4 text-md`"
          ></i>
        </button>
      </div>

      <div
        v-if="filter"
        class="z-50 origin-top-left absolute left-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
      >
        <div
          class="py-1 w-32"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <p
            @click="selectedYear = 0"
            class="cursor-pointer block px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 hover:text-gray-800"
            role="menuitem"
          >
            None
          </p>
          <p
            v-for="year in getYears"
            :key="year"
            @click="selectedYear = year.request_year"
            class="cursor-pointer block px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 hover:text-gray-800"
            role="menuitem"
          >
            {{ year.request_year }}
          </p>
        </div>
      </div>
    </div>

    <div v-if="loadingRequests" class="w-full flex items-center justify-center">
      <i
        :class="`lg:focus:shadow-sm w-12 text-center fas fa-circle-notch mt-4 text-5xl text-blue-primary animate-spin`"
      ></i>
    </div>

    <div
      v-else
      :class="` ${
        !loadingRequests ? '' : 'request-items'
      } w-full flex flex-col md:flex-row justify-center md:justify-start place-items-center`"
    >
      <div class="w-full flex flex-col md:flex-row mt-2 pb-20">
        <div
          v-for="request in companyRequests"
          :key="request.org_name"
          class="w-full md:w-1/2 lg:w-1/3 xl:w-80 p-4 max-h-72"
        >
          <transition name="fade">
            <request-card
              :key="request.org_name"
              :title="request.org_name"
              :total="request.amount"
              :year="request.request_year"
              :isGroup="true"
            ></request-card>
          </transition>
        </div>
        <!-- <div
          v-for="request in companyRequests"
          :key="request.org_name"
          class="w-full sm2:w-1/3 sm:w-80 md:w-1/3 lg:w-1/4 xl:w-80 p-4 max-h-72"
        >
          <transition name="fade">
            <request-card
              :key="request.org_name"
              :title="request.org_name"
              :total="request.amount"
              :year="request.request_year"
              :isGroup="true"
            ></request-card>
          </transition>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { requestMixin } from "@/mixins/requests";
import { onMounted, onUnmounted, ref, computed } from "vue";
import { useStore } from "vuex";
export default {
  mixins: [requestMixin],
  setup() {
    const $store = useStore();
    const loadingRequests = ref(true);
    const selectedYear = ref(0);
    let requestInterval = null;
    const companyRequests = computed(() => {
      if (selectedYear.value > 0)
        return $store.getters.getCompanyRequests.filter((req) => {
          return req.request_year == selectedYear.value;
        });
      else return $store.getters.getCompanyRequests;
    });

    function startFetchingRequests() {
      requestInterval = setInterval(async () => {
        await fetchRequests();
      }, 10000);
    }
    async function fetchRequests() {
      await $store.dispatch("getRequestYears");
      await $store.dispatch("getRequests").then(() => {
        loadingRequests.value = false;
      });
    }

    onMounted(async () => {
      loadingRequests.value = true;
      fetchRequests();
      startFetchingRequests();
    });
    onUnmounted(async () => {
      clearInterval(requestInterval);
    });

    return {
      selectedYear,
      companyRequests,
      loadingRequests,
    };
  },
};
</script>

<style></style>
